import React, { useContext } from 'react';
import { ReducerContext, UserContext, ProjectsContext } from "app";
import { dateFormat, DateDiff } from 'components/utils/utils';
import * as utils from '../../utils/utils';

export default function WorkLoadWindow(props) {
    let { projects } = useContext(ProjectsContext);
    const { user } = useContext(UserContext);
    const { dispatch } = useContext(ReducerContext);

    let incompletetaskcount = 0;
    let unconfirmedtaskcount = 0;
    let readyforinvoicetaskcount = 0;

    projects = projects || [];

    projects?.map((project, index) => {
        let cards = project.cards;
        project.nextdue_date = project.due_date;

        project.incompletetasks = [];
        project.unconfirmedtasks = [];
        project.readyforinvoicetasks = [];
        let account_manager = project.account_manager;

        cards?.map((card, index) => {

            card.tasks?.map((task, index) => {
                //task is not completed
                if (!task.completed_at) {

                    project.incompletetasks.push(task);
                    incompletetaskcount++;

                    if (task.due_date) {
                        if (!project.nextdue_date) {
                            project.nextdue_date = task.due_date;
                        }
                        else if (DateDiff(new Date(task.due_date), new Date(project.due_date)) < 0) {
                            project.nextdue_date = task.due_date;
                        }
                    }
                }

                // task is completed, not confirmed, and user is the creator or the user is the account manager
                if (!task.confirmed_at && task.completed_at && (account_manager === user._id)) {
                    project.unconfirmedtasks.push(task);
                    unconfirmedtaskcount++
                }

                if (task.confirmed_at && task.completed_at && !task.invoice_id && user._id == account_manager) {
                    project.readyforinvoicetasks.push(task);
                    readyforinvoicetaskcount++
                }
            })
        })
    })


    projects = projects?.sort((a, b) => {
        if (!a.nextdue_date)
            return -2;
        if (a.nextdue_date > b.nextdue_date)
            return 1;
        if (a.nextdue_date < b.nextdue_date)
            return -1;
        return 0;

    });

    projects = projects.filter(function (project) {
        return (project.incompletetasks.length > 0 || project.unconfirmedtasks.length > 0 || project.readyforinvoicetasks.length > 0);
    });

    return (
        <><h1>Current Workload Summary for {user.name}</h1><hr />
            You have <b>{projects.length}</b> projects, <b>{incompletetaskcount}</b> incomplete tasks, <b>{unconfirmedtaskcount}</b> tasks to approve, <b>{readyforinvoicetaskcount}</b> tasks to invoice.<hr />
            <div className='workloadwindow'>
                {
                    projects.length <= 0 && <> Loading Your Workload...</>
                }
                <ul>{
                    projects.map(project => {

                        return (
                            <li className="cursor" onClick={e => { dispatch({ type: "setActiveProjectID", payload: project._id }); dispatch({ type: "workLoadWindow", payload: null }) }}>
                                <h3>{project.client ? project.client?.name : "No client set!"}</h3>
                                <div>
                                    <div>
                                        <div className='row'>
                                            <div className='col-sm-6'><b>Project</b>: #{project.project_number} - {project.name}<br />
                                                <b>Created: </b> {dateFormat(project.created_at, "nice")}<br />
                                                <b>Creator</b>: {project.creator?.name}<br />
                                                {
                                                    project.account_manager == user._id && <>
                                                        <b>Account Manager</b>: {user.name}<br />
                                                    </>
                                                }</div>

                                            <div className='col-sm-6'><b>Timesheet: </b> {utils.getHoursMinsStr(project).str}
                                                <br />
                                                {
                                                    project.nextdue_date && <>
                                                        <b>Next Due Date: </b> {dateFormat(project.nextdue_date, "nice")}<br />
                                                    </>
                                                }

                                                {project.next_repeat_date &&
                                                    <>

                                                        <b>Project Will Repeat: </b> {dateFormat(project.next_repeat_date, "nice")}<br />
                                                    </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>


                                <br />
                                <ul>
                                    <li><h4>{project.incompletetasks.length} tasks to complete</h4>

                                        {project.incompletetasks.map(task => {
                                            return (
                                                <div key={project._id + `_` + task._id} className='row'>
                                                    <div className='col-sm-6'><b>{task.name}</b></div>
                                                    <div className='col-sm-3'>{utils.getHoursMinsStr(task).str}</div>
                                                </div>
                                            )
                                        })}

                                    </li>
                                    <li><h4>{project.unconfirmedtasks.length} tasks ready for approval</h4>

                                        {project.unconfirmedtasks.map(task => {
                                            return (
                                                <div key={project._id + `_` + task._id} className='row'>
                                                    <div className='col-sm-6'><b>{task.name}</b></div>
                                                    <div className='col-sm-3'>{utils.getHoursMinsStr(task).str}</div>
                                                </div>
                                            )
                                        })}

                                    </li>
                                    <li><h4>{project.readyforinvoicetasks.length} ready for invoicing</h4>

                                        {project.readyforinvoicetasks.map(task => {
                                            return (
                                                <div key={project._id + `_` + task._id} className='row'>
                                                    <div className='col-sm-6'><b>{task.name}</b></div>
                                                    <div className='col-sm-3'>{utils.getHoursMinsStr(task).str}</div>
                                                </div>
                                            )
                                        })}

                                    </li>
                                </ul>

                            </li>

                        )

                    })

                }
                </ul>
            </div>
        </>
    )

}