import { useContext } from 'react';
import { ProjectsContext } from 'app';

import { HeaderColor, HeaderTitle } from './header/header'

import OptionEllipsis from './options/option-ellipsis'
import * as utils from '../../../utils/utils';

export default function Taskbar() {
  const { activeProject } = useContext(ProjectsContext);
  const due_date = activeProject.due_date || activeProject.next_repeat_date;

  
  return (
    <div className="taskbar">
      <div className="taskbar__wrapper">
        <div className='taskbar__header'>
          <HeaderColor />
          <HeaderTitle />
        </div>
        <div style={{ display: "block" }} className="taskbar_details">
          Project # {activeProject.project_number} | {activeProject.client?.name} | {utils.dateFormat(activeProject.created_at, "nice")} - {utils.dateFormat(due_date, "nice")}<br />
          {utils.getHoursMinsStr(activeProject, false).str}
        </div>
      </div>

      <div className="taskbar__options">
        <OptionEllipsis />
      </div>

    </div>
  )
}