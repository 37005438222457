import React, { useEffect, useState } from "react";
import ReactQuill, { Quill } from "react-quill";
import * as Emoji from "quill-emoji";
import "react-quill/dist/quill.snow.css";
import "quill-emoji/dist/quill-emoji.css";
import "quill-mention/dist/quill.mention.css";
import "quill-mention";
import axios, { setResponseError } from 'utils/axiosConfig';

let atValues = [];
let hashValues = [];

const Link = Quill.import("formats/link");

Link.sanitize = function (url) {
  // quill by default creates relative links if scheme is missing.
  if (!url.startsWith("http://") && !url.startsWith("https://")) {
    return `http://${url}`;
  }
  return url;
};
Quill.register(Link, true);
Quill.register("modules/emoji", Emoji);

const mention = {
  allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
  mentionDenotationChars: ["@", "#"],
  linkTarget: "https://www.google.com",

  source: function (searchTerm, renderList, mentionChar,) {
    let values;

    if (mentionChar === "@") {
      values = atValues;
    } else {
      values = hashValues;
    }

    if (searchTerm.length === 0) {
      renderList(values, searchTerm);
    } else {
      const matches = [];
      for (let i = 0; i < values.length; i++)
        if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase()))
          matches.push(values[i]);
      renderList(matches, searchTerm);
    }
  },
};

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      ['link', 'image'],
    ],
    handlers: {
      image: imageHandler
    }
  },
  clipboard: {
    matchVisual: false,
  },
  mention

};

const formats = [
  "header",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "color",
  "image",
  "background",
  "align",
  "size",
  "font",
  "mention",
  "emoji"
];

function uploadImage(e, next) {

  const file = e.target.files[0]

  if (file) {
    let form = new FormData();

    const config = {
      headers: {
        "Content-Type": "multipart/form-data"
      }
    };

    form.append('image', file, file.name);

    axios({
      method: "post",
      url: "/uploadimage",
      data: form,
      headers: {
        'Content-Type': `multipart/form-data; boundary=${form._boundary}`,
      },

    })
      .then(function (res) {
        //handle success
        next(res);

      })
      .catch(function (response) {
        //handle error

      });

  }

}

function imageHandler() {

  const input = document.createElement('input');

  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async (e) => {

    const res = await uploadImage(e, (res) => {

      let uploadedImage = res.data.filepath;
      // Save current cursor state
      const range = this.quill.getSelection(true);

      // Insert temporary loading placeholder image
      this.quill.insertEmbed(range.index, 'image', `${window.location.origin}/images/loaders/placeholder.gif`);

      // Move cursor to right side of image (easier to continue typing)
      this.quill.setSelection(range.index + 1);

      // Remove placeholder image
      this.quill.deleteText(range.index, 1);

      // Insert uploaded image
      // this.quill.insertEmbed(range.index, 'image', res.body.image);
      this.quill.insertEmbed(range.index, 'image', uploadedImage);
    });


  };

}

function DataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',')
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1])
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0]

  const ia = new Uint8Array(byteString.length)
  for (let i = 0; i < byteString.length; i++)
    ia[i] = byteString.charCodeAt(i)

  return new Blob([ia], { type: mimeString })
}

function Editor(props) {

  const [editorHtml, setEditorHtml] = useState("");

  const handleChange = (html) => {

    // look for base64 images

    const imgTags = html.match(/<img [^>]*src="[^"]*"[^>]*>/gm);

    let sources = html?.match(/<img [^>]*src="[^"]*"[^>]*>/gm)?.map(x => x.replace(/.*src="([^"]*)".*/, '$1'));
    sources = sources || [];
    for (let s = 0; s < sources.length; s++) {

      let imgBase64 = sources[s];
      if (imgBase64.indexOf(";base64") > 0) {

        let timestamp = Date.now();
        const type = imgBase64.split(';')[0].split('/')[1];

        let formData = new FormData();

        const file = DataURIToBlob(imgBase64)

        formData.append('image', file, timestamp + '.' + type)
        html = html.replace(imgBase64, "imageholder-" + timestamp);

        axios({
          method: "post",
          url: "/uploadimage",
          data: formData,
          headers: {
            'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
          },
        })
          .then(function (res) {

            html = html.replace("imageholder-" + timestamp, res.data.filepath);

            setEditorHtml(html);
            props.changeHandler(html);
          })
          .catch(function (response) {

          });


      }
    }

    setEditorHtml(html);
    props.changeHandler(html);

  };

  useEffect(() => {
    if (props.value) {
      setEditorHtml(props.value);
    } else {
      setEditorHtml("");
    }
    if (props.values) {
      let hash = props.values
      hash.map((v) => {
        v["value"] = v["display"]
      })
      hashValues = hash
    }
    if (props.people) {
      console.log("props.people: ", props.people)
      let peoples = props.people.map(person => { return { "id": person._id, "value": person.name } })

      // peoples.map((v) => {
      //   v["value"] = v["display"]
      // })
      atValues = peoples
    }
  }, [props.value]);

  return (
    <div>
      <ReactQuill
        onPaste=""
        onChange={handleChange}
        value={editorHtml}
        modules={modules}
        formats={formats}
        bounds={".app"}
        placeholder={props.placeholder}
      />
    </div>
  );
}


function QuillEditor({
  placeHolder,
  name,
  value,
  onChange,
  hash,
  users
}) {

  return (
    <div className="custom-toolbar-example">
      <Editor
        placeholder={placeHolder}
        name={name}
        value={value}
        changeHandler={onChange}
        values={hash}
        people={users}
      />
    </div>
  );
}

export default QuillEditor;
