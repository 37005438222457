import React, { useState, useContext, useRef } from 'react';
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';
//import Quill from 'components/utils/quill.js';
import Clients from 'components/utils/clients/clients.js'
import Invoices from './invoices/invoices.js';
import { currencyFormat, dateFormat, getHoursMinsStr, addDays } from "../../utils/utils";

export function Project(props) {
  const { state, dispatch } = useContext(ReducerContext);
  //const { project } = useContext(ProjectsContext);
  const [project, setProject] = React.useState(props.project);

  const oProject = project;

  function updateProject(project) {
    dispatch({ type: "updateProject", payload: project })
  }

  function getProjectHoursMinsStr() {
    return getHoursMinsStr(project).str;
  }

  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave(e);
  }

  function handleSave(e) {
    let thisname = e.target.name;

    if (thisname === "repeat_str") {
      setRepeats(e)
    }
    if (thisname === "name") {
      setName(e);
    }
    if (thisname === "hourly_rate") {
      setHourlyRate(e);
    }
    if (thisname === "fixed_fee") {
      setFixedFee(e);
    }
    if (thisname === "quote") {
      setQuote();
    }
    if (thisname === "due_date") {
      setDueDate(e);
    }
    if (thisname === "description") {
      setProjectDescription(e);
    }


  }

  function set_next_repeat_date(e) {

    let next_repeat_date = dateFormat(e.target.value, "yyyy-mm-dd");

    setProject({ ...project, next_repeat_date, setproject_next_repeat_date: false })

    //console.log("project after next_repeat_date: ", project);

    axios.post('/a/project/' + project._id + '/update?type=nextrepeatdate',
      {
        next_repeat_date
      })

      .catch(err => {
        console.log("error updating... ")
        setResponseError(err, dispatch);
        updateProject(oProject);
      });

    updateProject(project)
  }

  function setRepeats(e) {

    let repeat_str = e.target.value;

    var today = new Date();

    switch (repeat_str) {
      case "weekly": {
        var next_repeat_date = today.setDate(today.getDate() + 7);
        break;
      }
      case "monthly": {
        var next_repeat_date = today.setMonth(today.getMonth() + 1);
        break;
      }

      case "quarterly": {
        var next_repeat_date = today.setMonth(today.getMonth() + 3);
        break;
      }
      case "bi-annually": {
        var next_repeat_date = today.setMonth(today.getMonth() + 6);
        break;
      }
      case "annually": {
        var next_repeat_date = today.setYear(today.getYear() + 1);
        break;
      }
      default: {
        //statements;
        break;
      }
    }
    setProject({ ...project, repeat_str: e.target.value, next_repeat_date, setproject_repeat: false });


    axios.post('/a/project/' + project._id + '/update?type=repeats',
      {
        repeat_str,
        next_repeat_date
      })

      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(oProject)
      });

    updateProject(Project)
  }

  function setDueDate(e) {

    setProject({ ...project, due_date: e.target.value, setproject_due_date: false })

    axios.post('/a/project/' + project._id + '/update?type=due_date',
      {
        due_date: e.target.value
      })

      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(oProject)
      });

    updateProject(project)
  }

  function setHourlyRate(e) {
    updateProject({ ...project, hourly_rate: e.target.value })


    setProject({ ...project, setproject_hourly_rate: false })

    axios.post('/a/project/' + project._id + '/update?type=hourly_rate',
      {
        hourly_rate: e.target.value
      })

      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(project)
      })
  }

  function setFixedFee(e) {

    setProject({ ...project, setproject_fixedfee: false })
    updateProject({ ...project, setproject_fixedfee: false, fixed_fee: e.target.value })

    axios.post('/a/project/' + project._id + '/update?type=fixed_fee',
      {
        fixed_fee: e.target.value
      })

      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(project)
      })
  }

  function addNewResource(e) {

    let newResource = { url: project.newresource_url, description: project.newresource_desc, created_at: "Just Now" };
    let resources = project.resources || [];
    resources.push(newResource)
    dispatch({ type: "updateproject", payload: { ...project, resources } })

    let index = project.resources.length - 1

    setProject({ ...project, newresource_desc: "", newresource_url: "", setresourcelink: false, resources })

    axios.post('/a/project/' + project._id + '/update?type=addresource',
      {
        newResource: newResource
      })
      .then(r => {
        project.resources[index]._id = r.data._id;
        dispatch({ type: "updateproject", payload: project })
      })
      .catch(err => {
        setResponseError(err, dispatch)
        dispatch({ type: "updateproject", payload: project })
      })
  }

  function removeResource(resource, i) {

    let resources = project.resources;
    resources.splice(i, 1);

    setProject({ ...project, resources });

    updateProject({ ...project, resources })
    axios.post('/a/project/' + project._id + '/update?type=removeresource',
      {
        resourceid: resource._id
      })
      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(project)
      })
  }

  function setQuote() {
    setProject({ ...project, setproject_quote: false })

    updateProject({ ...project, quote: project.quote })
    axios.post('/a/project/' + project._id + '/update?type=quote',
      {
        quote: project.quote
      })
      .then(r => {


      })
      .catch(err => {
        setResponseError(err, dispatch);
        updateProject(project)
      })
  }

  function setClient(client) {
    client = [client];
    project.client = client;

    project.hourly_rate = client.hourly_rate;

    setProject({ ...project, addclient: false, setproject_client: false })
    dispatch({ type: "updateproject", payload: project })
    axios.post('/a/project/' + project._id + '/update?type=client',
      {
        clientid: client._id
      })

      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateproject", payload: project })
      })
  }

  function setAccountManager(userid) {

    project.account_manager = userid;



    setProject({ ...project, account_manager: userid })
    dispatch({ type: "updateproject", payload: project })
    axios.post('/a/project/' + project._id + '/update?type=account_manager',
      {
        account_manager: userid
      })

      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateproject", payload: project })
      })
  }

  function toggleUser(user) {
    let index = project.users.findIndex(u => u._id == user._id);

    let users = project.users;
    if (index > -1) {
      //remove this user
      users.splice(index, 1)
    }
    else {
      //add this user
      users.push(user)
    }

    setProject({ ...project, users })

    axios.post('/a/project/' + project._id + '/update?type=usertoggle',
      {
        user
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })
    //add remove use
  }

  function setName(e) {

    let newName = e.target.value;
    dispatch({ type: "updateproject", payload: { ...project, name: newName } })
    setProject({ ...project, setproject_name: false })

    axios.post('/a/project/' + project._id + '/update?type=name',
      {
        name: newName
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateproject", payload: project })
      })
  }

  function setProjectDescription(e) {

    let newDescription = e.target.value;
    dispatch({ type: "updateproject", payload: { ...project, description: newDescription } })
    setProject({ ...project, setproject_description: false })

    axios.post('/a/project/' + project._id + '/update?type=description',
      {
        description: newDescription
      })
      .catch(err => {
        setResponseError(err, dispatch);
        dispatch({ type: "updateproject", payload: project })
      })
  }

  return (
    <>
      <div id="taskdetails" className='container'>
        <div className='row g-3'>
          <div className='col-sm-12'>
            {!project.setproject_name &&
              <>
                <a onClick={e => setProject({ ...project, setproject_name: true })}>{project.name ? <h1>{project.name}</h1> : "set project name"}</a>
              </>}
            {project.setproject_name &&
              <>
                <input
                  id="name"
                  name="name"
                  type="text"
                  className="form-control"
                  value={project.name}
                  onChange={e => { setProject({ ...project, name: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}
            <hr />
          </div>
        </div>
        <div className='row g-3'>
          <div className='col-sm-6'>
            <b>Project Number</b> : {project.project_number}<br />
            <b>Project Created</b> : {dateFormat(project.created_at, "nice")}<br />
            <b>Project Creator</b> : {project.creator?.name}<br />
            <b>Project Repeats: </b>
            {project.setproject_repeat && <>

              <select name="repeat_str" selected={project.repeat_str} className="form-control" onChange={e => { handleSave(e) }}>
                <option value="null">Does Not Repeat</option>
                <option value="weekly">Weekly</option>
                <option value="monthly">Monthly</option>
                <option value="quarterly">Quarterly</option>
                <option value="bi-annually">Bi-Annually</option>
                <option value="annually">Annually</option>
              </select>
            </>}
            {!project.setproject_repeat &&
              <a onClick={e => setProject({ ...project, setproject_repeat: true })}>
                {project.repeat_str &&
                  <>
                    {project.repeat_str}
                  </>
                }
                {!project.repeat_str &&
                  <>
                    set repeat
                  </>}
              </a>
            }

            <br />
            <>
              <b>Next Repeat Date: </b>
              {project.setproject_next_repeat_date &&
                <>
                  <input type="date" defaultValue={project.next_repeat_date} onBlur={e => set_next_repeat_date(e)} name="next_repeat_date" />
                </>}
              {!project.setproject_next_repeat_date &&
                <a onClick={e => setProject({ ...project, setproject_next_repeat_date: true })}>
                  {project.next_repeat_date ? dateFormat(project.next_repeat_date, "nice") : "set next repeat date"}
                </a>
              }
            </>

            <br />
            {!project.setproject_description &&
              <>
                <b>Description: </b>
                <div style={{ whiteSpace: "pre-wrap" }}>
                  <a onClick={e => setProject({ ...project, setproject_description: true })}>{project.description ? project.description : "set project description"}</a>
                </div>
              </>}
            {project.setproject_description &&
              <>
                <textarea

                  id="description"
                  name="description"
                  type="text"
                  className="form-control"
                  value={project.description}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, description: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}

            <Clients client={project.client} setClient={setClient} />

            {!project.setproject_hourly_rate &&
              <>
                <b>Hourly Rate: </b>
                <a onClick={e => setProject({ ...project, setproject_hourly_rate: true })}>{project.hourly_rate ? currencyFormat(project.hourly_rate) : "set project hourly rate"}</a>
              </>}
            {project.setproject_hourly_rate &&
              <>
                <input

                  id="hourly_rate"
                  name="hourly_rate"
                  type="text"
                  className="form-control"
                  value={project.hourly_rate}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, hourly_rate: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}
            <br />
            {!project.setproject_fixedfee &&
              <>
                <b>Fixed Fee: </b>
                <a onClick={e => setProject({ ...project, setproject_fixedfee: true })}>{project.fixed_fee ? currencyFormat(project.fixed_fee) : "set project fixed fee"}</a>
              </>}
            {project.setproject_fixedfee &&
              <>
                <input

                  id="fixed_fee"
                  name="fixed_fee"
                  type="text"
                  className="form-control"
                  value={project.fixed_fee}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, fixed_fee: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}


            <br />
            {!project.setproject_quote &&
              <>
                <b>Quote: </b>
                <a onClick={e => setProject({ ...project, setproject_quote: true })}>{project.quote ? currencyFormat(project.quote) : "set project quote"}</a>
              </>}
            {project.setproject_quote &&
              <>
                <input

                  id="quote"
                  name="quote"
                  type="text"
                  className="form-control"
                  value={project.quote}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, quote: e.target.value }) }}

                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
              </>}

            <br />
            {!project.setproject_due_date &&
              <>
                <b>Project Due Date: </b>
                <a onClick={e => setProject({ ...project, setproject_due_date: true })}>{project.due_date ? dateFormat(project.due_date, "nice") : "set due date"}</a>
              </>}
            {project.setproject_due_date &&
              <>
                <input type="date" onChange={e => { handleSave(e) }} name="due_date" />
              </>}

            <br /><b>Total Timesheet: </b> {getProjectHoursMinsStr()}




            <hr />
            <b>Resource Links</b> - <a onClick={e => setProject({ ...project, setresourcelink: true })}>Add</a> <br />
            {project.resources?.map((resource, index) => {
              return (
                <div className='row' key={index}>
                  <div className='col-sm-1'><a onClick={e => { removeResource(resource, index) }}>x</a></div>
                  <div className='col-sm-11'>
                    <b>{resource.description}</b><br />{resource.url}</div>
                </div>
              )
            })}
            {!state.setresourcelink &&
              <>

              </>}

            {project.setresourcelink &&
              <>
                <input

                  id="newresource_desc"
                  name="newresource_desc"
                  type="text"
                  placeholder="Resource Description"
                  className="form-control"
                  value={project.newresource_desc}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, newresource_desc: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
                <input

                  id="newresource_url"
                  name="newresource_url"
                  type="text"
                  placeholder="Resource URL"
                  className="form-control"
                  value={project.newresource_url}
                  style={{ width: '100%' }}
                  onChange={e => { setProject({ ...project, newresource_url: e.target.value }) }}
                  onBlur={handleSave}
                  onKeyDown={handleKeyDown}
                />
                <br />
                <button type="submit" onClick={e => addNewResource()} className="btn btn-primary text-right" name="save_newresource" value="Add" >Add</button>
                <a onClick={e => setProject({ ...project, setresourcelink: false })}>Cancel</a>
              </>}

          </div>
          <div className='col-sm-6'>
            <br />
            <div className='row'>
              <div className='col-sm-1'><b>Users</b></div>
              <div className='col-sm-3'><b>Account Manager</b></div>
              <div className='col-sm-8'></div>
            </div>

            {
              state.users?.map((user, index) => {
                return (<>
                  <div className='row' key={index}>
                    <div className='col-sm-1'><input type="checkbox" disabled={user._id === project.creator} value={user._id} onChange={e => { toggleUser(user) }} checked={project.users?.find(projectuser => projectuser._id == user._id)} /></div>
                    <div className='col-sm-3'><input type="radio" onClick={e => { setAccountManager(user._id) }} checked={project.account_manager === user._id} /></div>
                    <div className='col-sm-8'>{user.name}</div>
                  </div>
                </>)
              })
            }

          </div>
        </div>
        {
          project.account_manager == state.user._id &&
          <div className='row'>
            <div className='col-sm-12'>
              <hr />
              <Invoices />

            </div>
          </div>
        }

      </div>
    </>
  )
}