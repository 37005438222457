import { useContext } from 'react';
import { ReducerContext } from 'app';

import Header from './header/header';
import Projects from './projects/projects'

import Invoices from './invoices/invoices'
import User from './user/user';

export default function Menu() {

  const { state } = useContext(ReducerContext);

  return (
    <div className={`menu ${state.menuShown ? 'menu--shown' : 'menu--hidden'}`} id='menu'>
      <Header />
      <Projects />
      <Invoices />
      <User />
    </div>
  )
}