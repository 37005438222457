import React, { useState, useContext, useRef } from "react";
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

export default function AddCard(props) {
  const { activeProject } = useContext(ProjectsContext);
  const { dispatch } = useContext(ReducerContext);
  const [newActiveProject, setNewActiveProject] = useState(activeProject);

  let cards = activeProject.cards || [];

  const [editing, setEditing] = useState(false);
  const inputValueRef = useRef();

  function saveCard() {
    const newCardName = inputValueRef.current.value;
    const now = Date.now();

    const newCard =
    {
      name: newCardName,
      position: cards.length + 1,
      created_at: now,
      updated_at: now
    }

    activeProject.cards.push(newCard);
    dispatch({ type: "updateProject", payload: activeProject })

    axios.post('/a/project/' + activeProject._id + '/cards/create/',
      {
        newCard
      })
      .then(r => {

        let newCardID = r.data.cardids[0];
        console.log("newcardid: ", newCardID);

        let cards = activeProject.cards.map((card, index) => {
          return card.created_at == now ? { ...card, _id: newCardID } : card
        })
        console.log("new cards: ", cards);
        
        dispatch({ type: "updateProject", payload: { ...activeProject, cards } })
        // dispatch({ type: "updateActiveProject", payload: { ...activeProject, cards } })
      })
      .catch(err => {
        setResponseError(err, dispatch)
        dispatch({ type: "updateProject", payload: activeProject })
        //dispatch({ type: 'updateActiveProject', payload: activeProject })
      })

    inputValueRef.current.value = '';
  }

  function handleSave() {
    setEditing(false);

    if (inputValueRef.current.value !== '')
      saveCard();
  }

  function handleKeyDown(e) {
    if (e.key === "Enter")
      handleSave();

    else if (e.key === "Escape")
      setEditing(false);
  }

  return (
    <div className="tasks__add-card">
      {
        editing
          ? <input
            style={{ width: '100%', overflow: 'hidden' }}
            id='input--column'
            ref={inputValueRef}
            onBlur={handleSave}
            onKeyDown={handleKeyDown}
            autoFocus
          />

          : <button onClick={() => { setEditing(true) }}><FontAwesomeIcon icon={faPlus} /><span> ADD TASK LIST</span></button>
      }
    </div>
  )
}