import React, { useState, useContext, useRef } from 'react';
import { ProjectsContext, ReducerContext } from "app";
import axios, { setResponseError } from 'utils/axiosConfig';


export default function Clients(props) {

    const { state, dispatch } = useContext(ReducerContext);

    const [clientState, setClientState] = React.useState({ client: props.client, clients: [], addclient: false });

    function setClient(client) {
        setClientState({ ...clientState, client, clients: [], searchClient: client.name })
        props.setClient(client)
    }

    function searchClient(s) {

        if (!state.clients && !state.fetchingClients) {
            dispatch({ type: "getClients" })
        }


        let clientList = state.clients?.filter((client) => client.name.toLowerCase().includes(s.toLowerCase()));
        setClientState({ ...clientState, searchClient: s, clients: clientList })


    }

    function addClient() {
        let now = new Date();
        let thisClient = { name: clientState.newClient.name, accounts_email: clientState.newClient.accounts_email, contact_email: clientState.newClient.contact_email, hourly_rate: clientState.newClient.hourly_rate, address1: clientState.newClient.address1, address2: clientState.newClient.address2, town: clientState.newClient.town, city: clientState.newClient.city, postcode: clientState.newClient.postcode, created_at: now };
        var errors = [];


        if (!thisClient.name || thisClient.name === "") {
            errors.push("name");
        }

        if (!thisClient.accounts_email || thisClient.accounts_email === "") {
            errors.push("accounts_email");
        }
        if (!thisClient.contact_email || thisClient.contact_email === "") {
            errors.push("contact_email");
        }

        if (!thisClient.address1 || thisClient.address1 === "") {
            errors.push("address1");
        }
        if (!thisClient.town || thisClient.town === "") {
            errors.push("town");
        }
        if (!thisClient.city || thisClient.city === "") {
            errors.push("city");
        }
        if (!thisClient.postcode || thisClient.postcode === "") {
            errors.push("postcode");
        }
        setClientState({ ...clientState, errors });

        if (errors.length <= 0) {

            setClientState({ ...clientState, client: thisClient });
            dispatch({ type: "addclient", payload: thisClient })
            axios.post('/a/clients/create',
                {
                    client: thisClient
                })
                .then(function (r) {

                    let clients = state.clients.map((client, index) => {
                        return client.created_at == now ? { ...client, _id: r.data.newClient._id } : client
                    })

                    dispatch({ type: "setClients", payload: clients })
                    //thisClient._id = r.data.newClient._id
                    setClient(r.data.newClient);


                })
                .catch(err => {
                    setResponseError(err, dispatch);
                    //setNewActiveProject({ ...activeProject })
                })
        }

    }

    var newClient = {};


    return (
        <>
            <div>

                {
                    clientState.client && <>
                        <b>Client : </b>  <a onClick={e => { setClientState({ ...clientState, client: null }) }} >{clientState.client.name}</a>
                    </>
                }
                {
                    !clientState.client && <>
                        <input
                            id="client_search"
                            name="client_search"
                            type="text"
                            placeholder="Client Search"
                            className="form-control"
                            style={{ width: '100%' }}
                            value={clientState.searchClient || ""}
                            onChange={e => { searchClient(e.target.value) }}
                        />
                        <br />
                        {
                            state.fetchingClients && <>Loading Clients</>
                        }
                        {clientState.clients?.map((client, index) => {
                            return (
                                <>
                                    <a key={index} onClick={e => { setClient(client) }}>{client.name} - {client.city}</a><br />
                                </>
                            )
                        })}<br />
                        {
                            !clientState.client && clientState.clients?.length <= 0 && clientState.searchClient && <>
                                No Matching Clients Found
                                <br /><a onClick={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, name: clientState.searchClient }, addclient: true }) }} className="btn btn-primary">add new client</a><br /><br />
                            </>
                        }

                        {
                            clientState.addclient && <>
                                <br />
                                <div className='row'>
                                    <div className="col-sm-12">
                                        <b>New Client</b>
                                        <input

                                            id="newclient_name"
                                            name="newclient_name"
                                            value={clientState.newClient?.name}
                                            type="text"
                                            placeholder="Name"
                                            className={`form-control ${clientState.errors?.includes("name") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, name: e.target.value } }) }}
                                        />
                                    </div>
                                </div>

                                Address
                                <div className='row'>
                                    <div className='col-sm-12'>
                                        <input
                                            required
                                            id="newclient_address1"
                                            name="newclient_address1"
                                            type="text"
                                            placeholder="Address 1"
                                            className={`form-control ${clientState.errors?.includes("address1") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, address1: e.target.value } }) }}
                                        />
                                        <input

                                            id="newclient_address2"
                                            name="newclient_address2"
                                            type="text"
                                            placeholder="Address 2"
                                            className="form-control"
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, address2: e.target.value } }) }}
                                        />
                                        <input
                                            required
                                            id="newclient_town"
                                            name="newclient_town"
                                            type="text"
                                            placeholder="Town"
                                            className={`form-control ${clientState.errors?.includes("town") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, town: e.target.value } }) }}
                                        />
                                        <input
                                            required
                                            id="newclient_city"
                                            name="newclient_city"
                                            type="text"
                                            placeholder="City"
                                            className={`form-control ${clientState.errors?.includes("city") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, city: e.target.value } }) }}
                                        />
                                        <input
                                            required
                                            id="newclient_postcode"
                                            name="newclient_postcode"
                                            type="text"
                                            placeholder="Postcode"
                                            className={`form-control ${clientState.errors?.includes("postcode") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, postcode: e.target.value } }) }}
                                        />

                                        Contact Email
                                        <input
                                            required
                                            id="newclient_contactemail"
                                            name="newclient_contactemail"
                                            type="text"
                                            placeholder="Contact Email"
                                            className={`form-control ${clientState.errors?.includes("contact_email") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, contact_email: e.target.value } }) }}
                                        />
                                        Accounts Email
                                        <input
                                            required
                                            id="newclient_accountsemail"
                                            name="newclient_accountsemail"
                                            type="text"
                                            placeholder="Accounts Email"
                                            className={`form-control ${clientState.errors?.includes("accounts_email") ? "error" : ""}`}
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, accounts_email: e.target.value } }) }}
                                        />
                                        Hourly Rate
                                        <input

                                            id="newclient_hourlyrate"
                                            name="newclient_hourlyrate"
                                            type="text"
                                            placeholder="Hourly Rate"
                                            className="form-control"
                                            style={{ width: '100%' }}
                                            onChange={e => { setClientState({ ...clientState, newClient: { ...clientState.newClient, hourly_rate: e.target.value } }) }}
                                        />
                                    </div>
                                </div>

                                <br />
                                <button type="submit" onClick={e => addClient()} className="btn btn-primary text-right" name="save_newclient" value="Add" >Save New Client</button>
                                <a onClick={e => setClientState({ ...clientState, addclient: false })}>Cancel</a>
                            </>
                        }
                    </>
                }

            </div>
        </>
    )
}