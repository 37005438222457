import React, { useContext } from "react";
import { ProjectsContext, ReducerContext } from "app";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { notification } from 'antd';
import axios, { setResponseError } from 'utils/axiosConfig';
import { dateFormat } from 'components/utils/utils';

export default function Searchbar() {

  const { state, dispatch } = useContext(ReducerContext);
  const [thisState, setThisState] = React.useState({});

  function gotoNoti(noti) {
    let projects = state.projects;

    let newActiveProject = projects.find(project => project._id == noti.project_id)

    dispatch({ type: "removeUserNotification", payload: noti._id })

    if (newActiveProject) {
      if (noti.project_id) {
        dispatch({ type: "setActiveProjectID", payload: newActiveProject._id })

      }


      if (noti.task_id) {
        dispatch({ type: "setActiveProjectTaskID", payload: noti.task_id })
      }
    }
    else {
      dispatch(
        {
          type: 'setNotification',
          payload:
          {
            type: "error",
            header: "Failed to perform action",
            message: "Project no longer active"
          }
        })
      dispatch({ type: 'notificationShown', payload: true });
    }

  }

  function gotoLink(thisel) {
    console.log("going to : " + thisel);
    window.location(thisel);
  }

  function doSearch(e) {

    axios.post(`/a/search`,
      {
        searchstr: e.target.value
      })
      .then((res) => {
        setThisState({ ...thisState, results: res.results });
      })
      .catch(err => {

        setResponseError(err, dispatch)
      })


  }
  return (
    <>
      <div className={`searchbarwrapper ${state.menuShown && '--menu-shown'}`} id="searchbar">
        <div className="searchbar">
          <FontAwesomeIcon icon={faMagnifyingGlass} />
          <input type="text" onKeyUp={e => { doSearch(e) }} placeholder="Looking for something?" />
          {// we have results...
            thisState.results && <>
              {
                // map result types...
                thisState.results?.map(resultTypes => {
                  // map results of type
                  let resultlinks = resultTypes.map(result => {
                    return <>
                      <a onClick={e => (gotoLink(result.url))}>{result.searchTitle}</a>
                    </>
                  })

                  return <>
                    resultTypes.type + <br /> + resultlinks;
                  </>
                })}
            </>
          }
        </div>
        <div className={`notifications ${state.notifications?.length > 0 && '--on'}`}>
          <FontAwesomeIcon icon={faBell} />
          <div className='count'>{state.notifications?.length}</div>
          <div className='list'>
            {state.notifications?.map(notification => {
              return (
                <div className='notiitem' key={notification._id} onClick={e => { gotoNoti(notification) }}>
                  <div className='created'>{dateFormat(notification.createdAt)}</div>
                  {notification.description}</div>
              )
            })

            }
          </div>
        </div>

      </div>

    </>
  )
}