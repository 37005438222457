import React, { useRef, useLayoutEffect } from 'react';

import ListItem from "./list-item";

function List({ elements, itemtemplate, onClick, styles = null, classes = "reorderlist", ids = "" }) {

  const listRef = useRef(null);


  //const [state, setState] = React.useState({});
  // don't judge me
  useLayoutEffect(() => {
    const listElement = listRef.current;
    const storedScrollOffsetY = sessionStorage.getItem(`${ids}:y`);
    const storedScrollOffsetX = sessionStorage.getItem(`${ids}:x`);

    if (listElement) {
      listElement.scrollTop = Number(storedScrollOffsetY);
      listElement.scrollLeft = Number(storedScrollOffsetX);
    }

    // eslint-disable-next-line
  }, []);

  // don't judge me
  function handleScroll() {
    const listElement = listRef.current;

    if (listElement) {
      const scrollOffsetY = listElement.scrollTop;
      const scrollOffsetX = listElement.scrollLeft;

      sessionStorage.setItem(`${ids}:y`, scrollOffsetY);
      sessionStorage.setItem(`${ids}:x`, scrollOffsetX);
    }
  };

  return (
    elements &&
    <ul className={classes} id={ids}>

      {elements.map((item) => {
        return (
          <ListItem key={item._id} ListItem={itemtemplate} item={item} />
        )
      })}
    </ul>

  )
}

const MemoizedList = React.memo(List);
export default MemoizedList;