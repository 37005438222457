import axios, { setResponseConfirmation, setResponseError } from 'utils/axiosConfig';

export default function showConfirmation(projectsContext, userContext, reducerContext) {
  const { projects, setProjects, activeProject, setActiveProject } = projectsContext;
  const { user, setUser } = userContext;
  const { state, dispatch } = reducerContext;

  function archiveProject() {

    dispatch({ type: 'confirmationShown', payload: false })

    if (state.projOptions)
      dispatch({ type: 'setProjOptions', payload: null })

    //let newprojects = projects.filter(project => project._id !== activeProject._id);

    //dispatch({ type: 'setProjects', payload: newprojects })
    dispatch({ type: 'setUser', payload: { ...user, activeProject: null } })

    dispatch({ type: "updateProject", payload: { ...activeProject, archived_at: new Date() } })
    axios.post(`/a/project/${activeProject._id}/archive`)
      .then(() => {

        setResponseConfirmation("Succesfully archived project", "", dispatch)
      })
      .catch(err => {

        setResponseError(err, dispatch)
      })
  }

  dispatch(
    {
      type: 'setConfirmation',
      payload:
      {
        header: "Are you sure you want to archive this project?",
        message: "This action will remove the project from everyones list.",
        className: 'btn--confirmation--danger',
        confirmation: "Yes, I want to archive this project",
        rejection: "No, this project is still active",
        function: archiveProject
      }
    })

  dispatch({ type: 'confirmationShown', payload: true })
}