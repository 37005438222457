import React, { useContext, useRef, useLayoutEffect } from "react";
import { UserContext, ProjectsContext, ReducerContext } from "app";

import AddCard from "./add-card/add-card";
import Card from './card/card';

export default function Cards() {
  const { state, activeProject } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);
  const [thisState, setThisState] = React.useState({});
  const { dispatch } = useContext(ReducerContext);

  // order cards by todo tasks
  const cardsOrdered = activeProject.cards ? structuredClone(activeProject.cards).sort((a, b) => {
    let notcompletecount_a = a.tasks?.filter(task => { return !task.completed_at }) || [] // red
    let notconfirmedcount_a = a.tasks?.filter(task => { return task.completed_at && !task.confirmed_at }) || [] // orange

    let notcompletecount_b = b.tasks?.filter(task => { return !task.completed_at }) || []  //red
    let notconfirmedcount_b = b.tasks?.filter(task => { return task.completed_at && !task.confirmed_at }) || [] //orange

    return notcompletecount_b.length - notcompletecount_a.length || notconfirmedcount_b.length - notconfirmedcount_a.length
  }) : [];

  const listRef = useRef(null);

  // don't judge me
  useLayoutEffect(() => {
    const listElement = listRef.current;
    const storedScrollOffsetX = sessionStorage.getItem(`list--${activeProject._id}:x`);

    if (listElement)
      listElement.scrollLeft = Number(storedScrollOffsetX);

  }, [activeProject._id]);

  // don't judge me
  function handleScroll() {
    const listElement = listRef.current;

    if (listElement) {
      const scrollOffsetX = listElement.scrollLeft;
      sessionStorage.setItem(`list--${activeProject._id}:x`, scrollOffsetX);
    }
  };



  return (
    <>
      <div className="flex">
        <button onClick={e => setThisState({ ...thisState, viewstyle: "cards" })}>Cards</button> | <button onClick={e => setThisState({ ...thisState, viewstyle: "list" })}>List</button>
      </div>
      <br />

      <div className='quickfilters'>
        Todo: <button className={`todo ${thisState.todojobs ? "active" : ""}`} onClick={e => { if (thisState.todojobs) { setThisState({ ...thisState, todojobs: false }) } else { setThisState({ ...thisState, todojobs: true }) } }}></button>
        To Approve: <button className={`toapprove ${thisState.approvejobs ? "active" : ""}`} onClick={e => { if (thisState.approvejobs) { setThisState({ ...thisState, approvejobs: false }) } else { setThisState({ ...thisState, approvejobs: true }) } }}></button>
        To Invoice: <button className={`toinvoice ${thisState.invoicejobs ? "active" : ""}`} onClick={e => { if (thisState.invoicejobs) { setThisState({ ...thisState, invoicejobs: false }) } else { setThisState({ ...thisState, invoicejobs: true }) } }}></button>
      </div>

      <div className={"tasks " + thisState.viewstyle} id="tasks" ref={listRef} onScroll={handleScroll}>
        {cardsOrdered ? cardsOrdered.map(card => {

          if (thisState.todojobs || thisState.approvejobs || thisState.invoicejobs) {

            let tasks = card.tasks?.filter(task => {
              let completed = task.completed_at ? true : false;
              let toapprove = (completed && !task.confirmed_at) ? true : false;
              let toinvoice = task.confirmed_at && task.invoiceable && !task.invoice_id ? true : false;

              return ((thisState.todojobs && !completed) || (thisState.approvejobs && toapprove) || (thisState.invoicejobs && toinvoice))
            });

            card.tasks = tasks;
          }


          return <Card key={card._id} project={activeProject} card={card} />
        }) : null}
        <AddCard activeProject={activeProject} />

      </div>
    </>
  )
}