import React, { useRef, useLayoutEffect } from 'react';
import { Reorder, useDragControls, useMotionValue } from "framer-motion"
import { useRaisedShadow } from "./use-raised-shadow.ts";

function ListItem({ item, key, ListItem }) {
  const y = useMotionValue(0);
  const boxShadow = useRaisedShadow(y);
  const controls = useDragControls()

  return (
    <li value={item._id} id={item._id} style={{ boxShadow, y }}>
      <ListItem itemData={item} key={item._id} ></ListItem>
    </li>
  );
}

const MemoizedList = React.memo(ListItem);
export default MemoizedList;
