import React, { useContext } from "react";
import { UserContext, ProjectsContext, ReducerContext } from "app";

import { Screensaver, NoActiveProject } from './screensaver/screensaver';
import Searchbar from './searchbar/searchbar';
import Taskbar from './taskbar/taskbar';
import { TaskDetails } from './tasks/task/task-details/taskdetails';
import Popup from '../../popup/popup';


import Cards from "./tasks/cards";

function Dashboard() {

  const { projects, activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);


  if (state.fetchingData) {
    return <Screensaver />
  }

  if (!activeProject)
    return <NoActiveProject />

  if (activeProject?._id === undefined || activeProject._id === "loading")
    return <Screensaver />

  let activeTask = null;

  if (state.activeProjectTaskID) {

    activeProject.cards?.forEach(card => {
      card.tasks?.forEach(t => {
        if (t._id == state.activeProjectTaskID) {
          activeTask = t;
        }
      })
    })
  }

  return (
    <div className={`contentcontainer dashboard ${state.menuShown && 'dashboard--moved'}`} id="dashboard">
      {activeTask &&
        <>
          <Popup hide={() => { dispatch({ type: "setActiveProjectTaskID", payload: null }) }}>
            <TaskDetails task={activeTask} />
          </Popup>
        </>
      }

      <Searchbar />
      <Taskbar />
      <Cards />
    </div>
  )
}

const MemoizedDashboard = React.memo(Dashboard);
export default MemoizedDashboard;