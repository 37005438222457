import React, { useState, useContext, useRef } from 'react';
import { ProjectsContext, ReducerContext, UserContext } from "app";
import axios, { setResponseConfirmation, setResponseError } from 'utils/axiosConfig';

import { TransitionOpacity } from 'components/utils/transitions/transitions';
import { ButtonGlow } from 'components/utils/buttons/buttons';
import { ChromePicker } from 'react-color';
import Clients from 'components/utils/clients/clients.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBarsProgress, faXmark } from "@fortawesome/free-solid-svg-icons";

export default function ProjCreator() {
  const projectNameRef = useRef();

  const { user } = useContext(UserContext);
  const { projects, activeProject } = useContext(ProjectsContext);
  const { state, dispatch } = useContext(ReducerContext);
  const [projectState, setProjectState] = React.useState({ adding: false, name: "" });
  const [newColor, setNewColor] = useState('#4b99cc');
  const [pickerActive, setPickerActive] = useState(false);

  const colors =
  {
    backgroundColor: newColor,
    color: newColor,
  };

  function setClient(client) {
    setProjectState({ ...projectState, client })
  }


  function createProject() {

    setProjectState({ ...projectState, adding: true })

    let now = new Date();

    let newProject =
    {
      ...projectState,
      color: newColor,
      creator: user,
      created_at: now,
      _id: 0
    };

    projects.push(newProject);

    axios.post(`/a/projects/create`,
      {
        newProject
      })
      .then((res) => {

        var projects = state.projects.map((project, index) => {
          return (project.created_at == now) ? res.data.project : project;
        });

        dispatch({ type: "openProjectWindow", payload: res.data.project });
        dispatch({ type: "setProjects", payload: projects })
        dispatch({ type: "setActiveProjectID", payload: res.data.project._id })
        dispatch({ type: 'projCreatorShown', payload: false });
        setResponseConfirmation("Successfully created project", "", dispatch)
      })
      .catch(err => {
        setResponseError(err, dispatch)
      })

    if (window.innerWidth < 1337 && state.menuShown === true)
      dispatch({ type: 'menuShown', payload: false });
  }
  console.log("createproject: ", projectState);

  return (
    <TransitionOpacity className='overlay__bg--dark' onClick={() => { dispatch({ type: 'projCreatorShown', payload: false }) }} id='proj-creator'>
      <div className="proj-creator" onClick={e => { e.stopPropagation() }}>
        <h2 className="proj-creator__title">CREATE PROJECT <FontAwesomeIcon icon={faBarsProgress} /></h2>

        <ButtonGlow icon={faXmark} onClick={() => { dispatch({ type: 'projCreatorShown', payload: false }) }} />

        <Clients client={null} setClient={setClient} />
        <br /><br />

        {
          projectState?.adding && <>Please Wait</>
        }
        {
          !projectState.adding && <>
            <input
              className="proj-creator__input"
              id='input--name'
              onChange={e => setProjectState({ ...projectState, name: e.target.value })}
              type="text"
              placeholder="Name of the project"
              autoFocus
              autoComplete='off'
            />
          </>
        }
        {
          !projectState?.adding &&
          projectState?.client && projectState?.name !== "" &&


          <>

            {!projectState?.adding && <>
              <button className="proj-creator__submit" disabled={projectState?.adding ? "disabled" : ""} onClick={createProject}>{projectState?.adding ? "Please Wait" : "CREATE"}</button>
            </>}

          </>
        }

      </div>
    </TransitionOpacity >
  )
}